<template>
    <div class='index largeScreen'>
        <iframe width="100%" height="100%" frameborder="no" :src="url" />
    </div>
</template>

<script setup>
import { apiConfig } from '../../../api/centerMonitor/viewConfig';
import { onMounted, ref } from 'vue';
import { useRoute } from "vue-router";
const route = useRoute();
const url = ref('')

onMounted(() => {
    if (route.query.viewId) {
        apiConfig.detail(route.query.viewId).then(({ result }) => {
            url.value = result.viewUrl
        })
    } else if (location.hash.includes('custom')) {
        url.value = location.origin + location.hash.split('/centerMonitor/largeScreen')[1]
    }

})
</script>
<style lang='less' scoped>
.largeScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}
</style>